import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/global/seo"
import BreadCrumbs from "../components/global/BreadCrumbs"
import FilterCard from "../components/catalog/filterCard"
import CompaniesList from "../components/catalog/catalogList"
import { slugify } from "../utils/transcriptSlug"
// import { slugifyOld } from "../utils/transcriptSlugOld"

const Oblast = ({ data, pageContext }) => {
  const cities = data.allMapCityCsv.nodes
  const companies = data.allTahoservCsv.nodes
  const { oblName, oblNameRodovyi } = pageContext
  const companyBreadCrumbs = [
    { position: 1, name: "Тахографи", url: "/", isLast: false },
    { position: 2, name: "Компанії", url: "/kompaniyi/", isLast: false },
    { position: 3, name: `${oblName} область`, url: "", isLast: true },
  ]
  return (
    <Layout>
      <Seo
        title={`Тахографи в компаніях ${oblNameRodovyi} області`}
        description={`Знайти компанії в ${oblNameRodovyi} області з продажу, обслуговання і ремонту тахографів на порталі Taho.org.ua.`}
      />
      <main className="max-w-7xl mx-auto px-4 sm:px-6">
        <BreadCrumbs breadcrumbs={companyBreadCrumbs} />

        <h1 className="text-4xl font-bold mt-6 mb-4">
          Компанії {oblNameRodovyi} області з обслуговання тахографів
        </h1>
        <div className="py-6">
          <div>Населені пункти {oblNameRodovyi} області:</div>
          {cities.map(city => {
            let cityCompaniesCount = companies.filter(
              obl => obl.mapCity === city.cityName
            ).length
            return (
              <FilterCard
                key={city.id}
                city={city.cityName}
                oblast={city.cityObl}
                oblSlug={`/${slugify(city.cityObl)}/`}
                citySlug={city.slug}
                count={cityCompaniesCount}
              />
            )
          })}
        </div>
        <CompaniesList companies={companies} />
      </main>
    </Layout>
  )
}

export const query = graphql`
  query ($oblName: String!) {
    allMapCityCsv(filter: { cityObl: { eq: $oblName } }) {
      nodes {
        id
        cityObl
        cityName
        slug
      }
    }
    allTahoservCsv(
      sort: { fields: sortID, order: DESC }
      filter: { mapObl: { eq: $oblName } }
    ) {
      nodes {
        id
        slug
        idNumber
        title
        shortname
        companyType
        address
        mapCity
        mapObl
        mapCityObl
        verifServ
        digitServ
        analogServ
      }
    }
  }
`

export default Oblast
