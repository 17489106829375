const slugify = function slugify(text) {
  text = text.toString().toLowerCase().trim()

  const sets = [
    { to: "a", from: "[АаÀÁÂÃÄÅÆĀĂĄẠẢẤẦẨẪẬẮẰẲẴẶἀ]" },
    { to: "b", from: "[Бб]" },
    { to: "v", from: "[Вв]" },
    { to: "h", from: "[ГгĜĞĢǴ]" },
    { to: "g", from: "[Ґґ]" },
    { to: "d", from: "[ДдÐĎĐÞ]" },
    { to: "e", from: "[ЕеЭэÈÉÊËĒĔĖĘĚẸẺẼẾỀỂỄỆ]" },
    { to: "yo", from: "[Ёё]" },
    { to: "ye", from: "[Єє]" },
    { to: "zh", from: "[Жж]" },
    { to: "z", from: "[ЗзŹŻŽ]" },
    { to: "y", from: "[Ии]" },
    { to: "y", from: "[ĴЙй]" },
    { to: "i", from: "[ІіÌÍÎÏĨĪĮİỈỊ]" },
    { to: "yi", from: "[Її]" },
    { to: "k", from: "[КкĶ]" },
    { to: "l", from: "[ЛлĹĻĽŁ]" },
    { to: "m", from: "[МḾ]" },
    { to: "n", from: "[НнÑŃŅŇ]" },
    { to: "o", from: "[ОоÒÓÔÕÖØŌŎŐỌỎỐỒỔỖỘỚỜỞỠỢǪǬƠ]" },
    { to: "p", from: "[Ппṕ]" },
    { to: "r", from: "[РрŔŖŘ]" },
    { to: "s", from: "[СсÇĆĈČСсßŚŜŞŠȘ]" },
    { to: "t", from: "[ТтŢŤ]" },
    { to: "u", from: "[УуÙÚÛÜŨŪŬŮŰŲỤỦỨỪỬỮỰƯ]" },
    { to: "f", from: "[Фф]" },
    { to: "kh", from: "[ХхĤḦ]" },
    { to: "ts", from: "[Цц]" },
    { to: "ch", from: "[Чч]" },
    { to: "sh", from: "[Шш]" },
    { to: "sch", from: "[Щщ]" },
    { to: "yu", from: "[Юю]" },
    { to: "ya", from: "[Яя]" },
    { to: "ij", from: "[Ĳ]" },
    { to: "oe", from: "[Œ]" },
    { to: "w", from: "[ẂŴẀẄ]" },
    { to: "x", from: "[ẍ]" },
    { to: "y", from: "[ÝŶŸỲỴỶỸ]" },
    { to: "-", from: "[·/_,:;]" },
    { to: "", from: "[ьъ']" },
  ]

  sets.forEach(set => {
    text = text.replace(new RegExp(set.from, "gi"), set.to)
  })

  return text
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^-a-zа-я\u0370-\u03ff\u1f00-\u1fff]+/g, "") // Remove all non-word chars
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, "") // Trim - from end of text
}

module.exports.slugify = slugify
